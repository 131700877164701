import { CatalogPromotion } from '@hiwaldo/sdk/interfaces';

export interface CampaignEffect {
  active: boolean;
}

/**
 * @description This hook is used to determine if a campaign is active. Currently,
 * the only logic is to check if there are any active promotions. In future, this
 * hook can be used to determine if a campaign is active based on other criteria.
 */
export const useCampaignEffect = (
  activePromotions: CatalogPromotion[]
): CampaignEffect => {
  const isActive = activePromotions?.length > 0;

  return {
    active: isActive,
  };
};
