import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlickCarouselStyles } from '../../../css/molecules/slick-carousel.styles';
import { SlickCarouselProps } from './slick-carousel.props';

function NextArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        zIndex: 9999999,
        backgroundImage: 'url(images/icons/slider-arrow-tangerine.svg)',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'sans-waldo',
        content: 'none',
        transform: 'rotate(180deg)',
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: { className: any; style: any; onClick: any }) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        backgroundImage: 'url(images/icons/slider-arrow-tangerine.svg)',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'sans-waldo',
        content: 'unset',
      }}
      onClick={onClick}
    />
  );
}

export const SlickCarouselMolecule = (props: SlickCarouselProps) => {
  const classes = SlickCarouselStyles();
  const defaults = {
    nextArrow: (
      <NextArrow
        className={classes.slickArrows}
        style={undefined}
        onClick={undefined}
      />
    ),
    prevArrow: (
      <PrevArrow className={undefined} style={undefined} onClick={undefined} />
    ),
    dashClass: `${classes.slickDash}`,
    dotsClass:
      props.dotsTheme == 'orange'
        ? `${classes.slickDots}`
        : props.dotsTheme == 'default'
        ? `${classes.slickDefault}`
        : `${classes.slickDash}`,
  };
  return (
    <Slider {...defaults} {...props.settings} asNavFor={props.asForNav}>
      {props.children}
    </Slider>
  );
};

SlickCarouselMolecule.defaultProps = { dotsTheme: 'orange' };
