import { createUseStyles } from 'react-jss';

export const SlickCarouselStyles = createUseStyles({
  slickDash: {
    position: 'absolute',
    top: '0px',
    display: 'block',
    width: '100%',
    padding: '0',
    margin: '0 0 20px 0',
    listStyle: 'none',
    textAlign: 'right',

    '& li': {
      position: 'relative',
      display: 'inline-block',
      width: '24px',
      height: '1px',
      margin: '0px 8px 0 0',
      padding: '1px',
      cursor: 'pointer',
      borderRadius: '20px',

      '& button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: '24px',
        height: '1px',
        padding: '1px',
        cursor: 'pointer',
        color: 'transparent',
        border: '1px solid #ccc7be',
        opacity: '1',
        borderRadius: '20px',
        background: '#ccc7be',
        transition: 'transform .2s ease-in-out',

        '&:hover , &:focus': {
          outline: 'none',
          '&:before': {
            opacity: 1,
          },
        },

        '&:before': {
          fontSize: '6px',
          lineHeight: '20px',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '20px',
          height: '20px',
          content: '•',
          textAlign: 'center',
          opacity: '.25',
          color: 'black',
          '-webkitFontSmoothing': 'antialiased',
          '-mozOsxFontSmoothing': 'grayscale',
        },
      },
      '&.slick-active button': {
        background: '#0032a0',
        border: '1px solid #0032a0',
      },
    },
  },
  slickDots: {
    position: 'absolute',
    bottom: '-35px',
    display: 'block',
    width: '100%',
    padding: '0',
    margin: '0 0 20px 0',
    listStyle: 'none',
    textAlign: 'center',

    '& li': {
      position: 'relative',
      display: 'inline-block',
      width: '16px',
      height: '16px',
      margin: '0 8px',
      padding: '0',
      cursor: 'pointer',
      borderRadius: '20px',

      '&.slick-active': {
        background: '#fe5000',
        borderColor: '#fe5000',
        transform: 'scale(1)',
      },

      '& button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: '16px',
        height: '16px',
        padding: '5px',
        cursor: 'pointer',
        color: 'transparent',
        border: '1px solid #fe5000',
        outline: 'none',
        borderRadius: '20px',
        background: '0 0',
        transition: 'transform .2s ease-in-out',
        transform: 'none',

        '&:hover , &:focus': {
          outline: 'none',
          '&:before': {
            opacity: 1,
          },
        },
        '&:before': {
          fontSize: '6px',
          lineHeight: '20px',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '20px',
          height: '20px',
          content: '•',
          textAlign: 'center',
          opacity: '.25',
          color: 'black',
          '-webkitFontSmoothing': 'antialiased',
          '-mozOsxFontSmoothing': 'grayscale',
        },
      },
    },
  },
  slickDefault: {
    position: 'absolute',
    bottom: '-10px',
    left: '25%',
    display: 'block',
    width: '50%',
    padding: '0',
    margin: '20px 0 20px 0',
    listStyle: 'none',
    textAlign: 'center',

    '& li': {
      position: 'relative',
      display: 'inline-block',
      width: '16px',
      height: '16px',
      margin: '0 8px',
      padding: '0',
      cursor: 'pointer',
      borderRadius: '20px',

      '&.slick-active': {
        background: '#9b9696',
        borderColor: '#9b9696',
        transform: 'scale(1)',
      },

      '& button': {
        fontSize: 0,
        lineHeight: 0,
        display: 'block',
        width: '16px',
        height: '16px',
        padding: '5px',
        cursor: 'pointer',
        outline: 'none',
        borderRadius: '20px',
        background: 'rgba(155, 150, 150, 0.4)',
        transition: 'transform .2s ease-in-out',
        transform: 'none',

        '&:hover , &:focus': {
          outline: 'none',
          '&:before': {
            opacity: 1,
          },
        },
        '&:before': {
          fontSize: '6px',
          lineHeight: '20px',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '20px',
          height: '20px',
          content: '•',
          textAlign: 'center',
          opacity: '.25',
          color: 'black',
          '-webkitFontSmoothing': 'antialiased',
          '-mozOsxFontSmoothing': 'grayscale',
        },
      },
    },
  },

  '.paginator': {
    position: 'relative',
    float: 'right',
    marginBottom: '20px',

    '& li': {
      marginTop: '20px',
      position: 'relative',
      float: 'left',

      marginRight: '20px',

      '&.prev': {
        display: 'block',
        height: '20px',
        width: '20px',
        backgroundImage: 'url(images/icons/slider-arrow-tangerine.svg)',
      },

      '&.next': {
        display: 'block',
        height: '20px',
        width: '20px',
        backgroundImage: 'url(images/icons/slider-arrow-tangerine.svg)',
      },
    },
  },

  slickArrows: {
    '.slick-prev': {
      left: '25px',
      content: 'unset',
      color: 'transparent',
      display: 'none',

      '&:before': {
        left: '25px',
        content: 'unset',
        color: 'transparent',
        display: 'none',
      },
    },
    '.slick-next:before': {
      left: '25px',
      content: 'unset',
      color: 'transparent',
      display: 'none',
    },
    '.slick-next': {
      left: '25px',
      content: 'unset',
      color: 'transparent',
      display: 'none',
    },
  },
});
