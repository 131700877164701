import {
  ProductBtnLabels,
  ProductDescLabels,
  ProductImagesLabels,
  ProductPricingLabels,
  ProductTitleLabels,
  ProductTags,
  Route,
} from '../../../enums';
import { Region, RenewalSubscriptionStatus } from '@hiwaldo/sdk';

export const homeOurProducts = (
  region: Region,
  customerSubscriptionStatus: RenewalSubscriptionStatus
): any[] => {
  const regionalOurProducts: any = [];
  let dailyLensPrice = ProductPricingLabels.DAILY_LENS_US;
  let vitaminLensPrice;
  let blgPrice = ProductPricingLabels.BLG_US;
  let btnLabelScenario = 'GET FREE TRIAL';

  switch (region) {
    case Region.UK: {
      dailyLensPrice = ProductPricingLabels.DAILY_LENS_UK;
      vitaminLensPrice = ProductPricingLabels.VITAMIN_UK;
      blgPrice = ProductPricingLabels.BLG_UK;
      break;
    }
    case Region.EU: {
      dailyLensPrice = ProductPricingLabels.DAILY_LENS_EU;
      vitaminLensPrice = ProductPricingLabels.VITAMIN_EU;
      break;
    }
    case Region.SE: {
      dailyLensPrice = ProductPricingLabels.DAILY_LENS_SE;
      vitaminLensPrice = ProductPricingLabels.VITAMIN_SE;
      break;
    }
  }

  if (customerSubscriptionStatus === RenewalSubscriptionStatus.ACTIVE) {
    btnLabelScenario = ProductBtnLabels.DAILY_LENS_ACTIVE;
  }

  if (region === Region.US) {
    regionalOurProducts.push({
      title: ProductTitleLabels.HYDRABOOST_PLUS,
      titleExt: ProductTitleLabels.HYDRABOOST_PLUS_EXT,
      link: Route.PRODUCTS_HYDRABOOST_PLUS,
      summary: ProductDescLabels.HYDRABOOST_PLUS,
      pricing: (
        <>
          <span className="font-medium">
            {ProductPricingLabels.HYDRABOOST_PLUS}
          </span>{' '}
          per box
        </>
      ),
      btnLabel: ProductBtnLabels.HYDRABOOST_PLUS,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.HYDRABOOST_PLUS,
      imageHover: ProductImagesLabels.HYDRABOOST_PLUS,
      tag: ProductTags.SUNGLASSES_PROMO,
      promotion: true,
    });
    regionalOurProducts.push({
      title: ProductTitleLabels.DAILY_LENS,
      link: Route.PRODUCTS_ORIGINAL,
      summary: ProductDescLabels.DAILY_LENS,
      pricing: (
        <>
          <span className="font-medium">{dailyLensPrice}</span> per box
        </>
      ),
      btnLabel: btnLabelScenario,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.DAILY_LENS,
      imageHover: ProductImagesLabels.DAILY_LENS_HOVER,
      promotion: false,
    });
  }

  regionalOurProducts.push({
    title: ProductTitleLabels.STARTER_KIT,
    link: Route.PRODUCTS_EYE_STARTER_KIT,
    summary: ProductDescLabels.STARTER_KIT,
    pricing: (
      <>
        <span className="font-medium">{ProductPricingLabels.STARTER_KIT}</span>
        <span className="ml-0.5 line-through text-opacity-50">$30</span>
      </>
    ),
    btnLabel: ProductBtnLabels.STARTER_KIT,
    btnLink: Route.GET_STARTED,
    image: ProductImagesLabels.STARTER_KIT,
    imageHover: ProductImagesLabels.STARTER_KIT_HOVER,
    promotion: false,
  });

  if (region !== Region.US) {
    regionalOurProducts.push({
      title: ProductTitleLabels.VITAMIN,
      link: Route.PRODUCTS_VITAMIN,
      summary: ProductDescLabels.VITAMIN,
      pricing: (
        <>
          <span className="font-medium">{vitaminLensPrice}</span> per box
        </>
      ),
      btnLabel: ProductBtnLabels.VITAMIN,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.VITAMIN,
      imageHover: ProductImagesLabels.VITAMIN,
      promotion: false,
    });
  }

  if (region === Region.US) {
    regionalOurProducts.push({
      title: ProductTitleLabels.DROPS,
      link: Route.PRODUCT_HYDRATION_DROPS,
      summary: ProductDescLabels.DROPS,
      pricing: (
        <>
          <span className="font-medium">{ProductPricingLabels.DROPS}</span>
        </>
      ),
      btnLabel: ProductBtnLabels.DROPS,
      btnLink: Route.PRODUCT_HYDRATION_DROPS,
      image: ProductImagesLabels.DROPS,
      imageHover: ProductImagesLabels.DROPS,
    });
  }

  if (region === Region.US || region === Region.UK) {
    regionalOurProducts.push({
      title: ProductTitleLabels.BLG,
      link: Route.PRODUCTS_BLG_GENDER_SELECTION,
      summary: ProductDescLabels.BLG,
      pricing: (
        <>
          <span className="font-medium">{blgPrice}</span>
        </>
      ),
      btnLabel: ProductBtnLabels.BLG,
      btnLink: Route.PRODUCTS_BLG_GENDER_SELECTION,
      image: ProductImagesLabels.BLG,
      imageHover: ProductImagesLabels.BLG,
    });
  }

  return regionalOurProducts;
};

export const shopOurProducts = {
  OUR_PRODUCTS_US: [
    {
      title: ProductTitleLabels.HYDRABOOST_PLUS,
      titleExt: ProductTitleLabels.HYDRABOOST_PLUS_EXT,
      link: Route.PRODUCTS_HYDRABOOST_PLUS,
      summary: ProductDescLabels.HYDRABOOST_PLUS,
      pricing: (
        <>
          <span className="font-medium">
            {ProductPricingLabels.HYDRABOOST_PLUS}
          </span>{' '}
          per box
        </>
      ),
      btnLabel: ProductBtnLabels.HYDRABOOST_PLUS,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.HYDRABOOST_PLUS,
      imageHover: ProductImagesLabels.HYDRABOOST_PLUS,
      tag: ProductTags.SUNGLASSES_PROMO,
      promotion: true,
      active: true,
    },
    {
      title: ProductTitleLabels.DAILY_LENS,
      titleExt: false,
      link: Route.PRODUCTS_ORIGINAL,
      summary: ProductDescLabels.DAILY_LENS,
      pricing: (
        <>
          <span className="font-medium">$20</span> per box
        </>
      ),
      btnLabel: 'GET FREE TRIAL',
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.DAILY_LENS,
      imageHover: ProductImagesLabels.DAILY_LENS_HOVER,
      tag: ProductTags.SUNGLASSES_PROMO,
      promotion: true,
      active: true,
    },
    {
      title: ProductTitleLabels.STARTER_KIT,
      titleExt: false,
      link: Route.PRODUCTS_EYE_STARTER_KIT,
      summary: ProductDescLabels.STARTER_KIT,
      pricing: (
        <>
          <span className="font-medium">$7</span>
          <span className="ml-0.5 line-through text-opacity-50">$30</span>
        </>
      ),
      btnLabel: ProductBtnLabels.STARTER_KIT,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.STARTER_KIT,
      imageHover: ProductImagesLabels.STARTER_KIT_HOVER,
      tag: ProductTags.SUNGLASSES_PROMO,
      promotion: true,
      active: false,
    },
    {
      title: ProductTitleLabels.DROPS,
      titleExt: false,
      link: Route.PRODUCT_HYDRATION_DROPS,
      summary: ProductDescLabels.DROPS,
      pricing: (
        <>
          <span className="font-medium">{ProductPricingLabels.DROPS}</span>
        </>
      ),
      btnLabel: ProductBtnLabels.DROPS,
      btnLink: Route.PRODUCT_HYDRATION_DROPS,
      image: ProductImagesLabels.DROPS,
      imageHover: ProductImagesLabels.DROPS,
      tag: '',
      promotion: false,
      active: false,
    },
    {
      title: ProductTitleLabels.BLG,
      titleExt: false,
      link: Route.PRODUCTS_BLG_GENDER_SELECTION,
      summary: ProductDescLabels.BLG,
      pricing: (
        <>
          <span className="font-medium">$75</span>
        </>
      ),
      btnLabel: ProductBtnLabels.BLG,
      btnLink: Route.PRODUCTS_BLG_GENDER_SELECTION,
      image: ProductImagesLabels.BLG,
      imageHover: ProductImagesLabels.BLG,
      tag: '',
      promotion: true,
      active: true,
    },
  ],
  OUR_PRODUCTS_UK: [
    {
      title: ProductTitleLabels.DAILY_LENS,
      titleExt: false,
      link: Route.PRODUCTS_ORIGINAL,
      summary: ProductDescLabels.DAILY_LENS,
      pricing: (
        <>
          <span className="font-medium">£12</span> per box
        </>
      ),
      btnLabel: 'GET FREE TRIAL',
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.DAILY_LENS,
      imageHover: ProductImagesLabels.DAILY_LENS_HOVER,
      tag: ProductTags.HYDRABOOST_PLUS_TAG,
      promotion: false,
      active: true,
    },
    {
      title: ProductTitleLabels.VITAMIN,
      titleExt: false,
      link: Route.PRODUCTS_VITAMIN,
      summary: ProductDescLabels.VITAMIN,
      pricing: (
        <>
          <span className="font-medium">£16</span> per box
        </>
      ),
      btnLabel: ProductBtnLabels.VITAMIN,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.VITAMIN,
      imageHover: ProductImagesLabels.VITAMIN,
      tag: ProductTags.HYDRABOOST_PLUS_TAG,
      promotion: false,
      active: true,
    },
    {
      title: ProductTitleLabels.BLG,
      titleExt: false,
      link: Route.PRODUCTS_BLG_GENDER_SELECTION,
      summary: ProductDescLabels.BLG,
      pricing: (
        <>
          <span className="font-medium">£65</span>
        </>
      ),
      btnLabel: ProductBtnLabels.BLG,
      btnLink: Route.PRODUCTS_BLG_GENDER_SELECTION,
      image: ProductImagesLabels.BLG,
      imageHover: ProductImagesLabels.BLG,
      tag: '',
      promotion: false,
      active: true,
    },
  ],
  OUR_PRODUCTS_EU: [
    {
      title: ProductTitleLabels.DAILY_LENS,
      titleExt: false,
      link: Route.PRODUCTS_ORIGINAL,
      summary: ProductDescLabels.DAILY_LENS,
      pricing: (
        <>
          <span className="font-medium">16 €</span> per box
        </>
      ),
      btnLabel: 'GET FREE TRIAL',
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.DAILY_LENS,
      imageHover: ProductImagesLabels.DAILY_LENS_HOVER,
      tag: '',
      promotion: false,
      active: true,
    },
    {
      title: ProductTitleLabels.VITAMIN,
      titleExt: false,
      link: Route.PRODUCTS_VITAMIN,
      summary: ProductDescLabels.VITAMIN,
      pricing: (
        <>
          <span className="font-medium">19 €</span> per box
        </>
      ),
      btnLabel: ProductBtnLabels.VITAMIN,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.VITAMIN,
      imageHover: ProductImagesLabels.VITAMIN,
      tag: '',
      promotion: false,
      active: true,
    },
  ],
  OUR_PRODUCTS_SE: [
    {
      title: ProductTitleLabels.DAILY_LENS,
      titleExt: false,
      link: Route.PRODUCTS_ORIGINAL,
      summary: ProductDescLabels.DAILY_LENS,
      pricing: (
        <>
          <span className="font-medium">150 kr</span> per box
        </>
      ),
      btnLabel: 'GET FREE TRIAL',
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.DAILY_LENS,
      imageHover: ProductImagesLabels.DAILY_LENS_HOVER,
      tag: '',
      promotion: false,
      active: true,
    },
    {
      title: ProductTitleLabels.VITAMIN,
      titleExt: false,
      link: Route.PRODUCTS_VITAMIN,
      summary: ProductDescLabels.VITAMIN,
      pricing: (
        <>
          <span className="font-medium">200 kr</span> per box
        </>
      ),
      btnLabel: ProductBtnLabels.VITAMIN,
      btnLink: Route.GET_STARTED,
      image: ProductImagesLabels.VITAMIN,
      imageHover: ProductImagesLabels.VITAMIN,
      tag: '',
      promotion: false,
      active: true,
    },
  ],
};
