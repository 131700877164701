export const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  autoplay: true,
  arrows: true,
  lazyLoad: true,
};

export const sliderHowItWorksSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  autoplay: true,
  arrows: false,
  lazyLoad: true,
};

export const sliderSettingsGetStarted = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  autoplay: true,
  arrows: true,
  lazyLoad: true,
};

export const sliderSettingsThumb = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 7,
  autoplaySpeed: 4000,
  autoplay: false,
  arrows: false,
  vertical: true,
  centerMode: true,
  focusOnSelect: true,
  className: 'slider-thumb',
};

export const sliderSettingsMain = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  focusOnSelect: true,
  autoplay: true,
  arrows: false,
  fade: true,
  className: 'slider-main',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
      },
    },
  ],
};

export const sliderSettingsThumbBLG = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 4,
  autoplaySpeed: 4000,
  autoplay: false,
  arrows: false,
  vertical: true,
  centerMode: true,
  focusOnSelect: true,
  className: 'slider-thumb',
};

export const sliderSettingsMainBLG = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplaySpeed: 4000,
  focusOnSelect: true,
  autoplay: true,
  arrows: false,
  fade: true,
  className: 'slider-main',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
      },
    },
  ],
};

export const homeOurProductsSettingUS = {
  lazyLoad: 'progressive',
  arrows: true,
  autoplay: false,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  mobileFirst: true,
  swipe: true,
  centerPadding: '1px',
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 812,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};

export const homeOurProductsSetting = {
  lazyLoad: 'progressive',
  arrows: true,
  autoplay: false,
  speed: 800,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  mobileFirst: true,
  swipe: true,
  centerPadding: '1px',
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
      },
    },
    {
      breakpoint: 812,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      },
    },
  ],
};
