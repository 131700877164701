export enum ProductTitleLabels {
  STARTER_KIT = 'Daily Eyecare Duo',
  DAILY_LENS = 'Daily Contact Lenses',
  DROPS = 'Eye Drops',
  BLG = 'Blue Light Glasses',
  VITAMIN = 'Vitamin Daily Contact Lenses',
  HYDRABOOST_PLUS = 'Daily Contact Lenses',
  HYDRABOOST_PLUS_EXT = 'with vitamin B12',
}
export enum ProductDescLabels {
  STARTER_KIT = '20 Daily Lenses + Hydration Drops',
  DAILY_LENS = 'Breathable and moisture-rich, providing all-day comfort',
  DROPS = '100% natural active ingredients, no harsh vasoconstrictors',
  BLG = 'Choose from 14 stylish and blue light blocking frames for screen heavy days',
  VITAMIN = 'Featuring a vitamin-infused saline solution for added hydration',
  HYDRABOOST_PLUS = 'An extra hydration boost',
}

export enum ProductBtnLabels {
  STARTER_KIT = 'GET STARTED',
  DAILY_LENS = 'GET FREE TRIAL',
  DROPS = 'ORDER NOW',
  BLG = 'BROWSE FRAMES',
  VITAMIN = 'GET FREE TRIAL',
  DAILY_LENS_ACTIVE = 'ORDER NOW',
  HYDRABOOST_PLUS = 'SHOP NOW',
}

export enum ProductImagesLabels {
  STARTER_KIT = 'eyecare_starter_kit.png',
  STARTER_KIT_HOVER = 'eyecare_starter_kit.png',
  DAILY_LENS = 'hydraboost.png',
  DAILY_LENS_HOVER = 'hydraboost_hover.png',
  DROPS = 'drops.png',
  DROPS_HOVER = 'drops_hover.png',
  BLG = 'blg.png',
  BLG_HOVER = 'blg_hover.png',
  VITAMIN = 'vitamin.png',
  VITAMIN_HOVER = 'vitamin.png',
  HYDRABOOST_PLUS = 'hydraboost-plus.png',
}

export enum ProductPricingLabels {
  STARTER_KIT = '$7',
  DROPS = '$13',
  DAILY_LENS_US = '$20',
  DAILY_LENS_UK = '£12',
  DAILY_LENS_EU = '16 €',
  DAILY_LENS_SE = '150 kr',
  BLG_US = '$75',
  BLG_UK = '£65',
  VITAMIN_UK = '£16',
  VITAMIN_SE = '200 kr',
  VITAMIN_EU = '19 €',
  HYDRABOOST_PLUS = '$24',
}

export enum ProductTags {
  HYDRABOOST_PLUS_TAG = 'New',
  SUNGLASSES_PROMO = 'Free sunglasses',
}
